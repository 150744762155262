<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Edit Digital Form Template
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="digitalFormEditForm"
        #default="{invalid}"
      >
        <b-card
          class="px-3 py-2"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Form Name*"
                label-for="h-digital-form-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-digital-form-name"
                    v-model="name"
                    placeholder="Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                label="Form Type*"
                label-for="h-digital-form-type"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Type"
                  vid="type"
                  rules="required"
                >
                  <b-form-input
                    id="h-digital-form-type"
                    v-model="type"
                    placeholder="Type"
                    :state="(errors.length > 0 || typeValidation) ? false : null"
                    name="icon"
                    @input="typeValidation == true ? typeValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="typeValidation"
                    class="text-danger"
                  >
                    {{ typeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Form Code*"
                label-for="h-digital-form-number"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Form Code"
                  vid="number"
                  rules="required"
                >
                  <b-form-input
                    id="h-digital-form-number"
                    v-model="number"
                    placeholder="Form Code"
                    :state="(errors.length > 0 || numberValidation) ? false : null"
                    name="number"
                    @input="numberValidation == true ? numberValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="numberValidation"
                    class="text-danger"
                  >
                    {{ numberError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Version"
                label-for="h-digital-form-revision"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="version"
                  vid="revision"
                  rules=""
                >
                  <b-form-input
                    id="h-digital-form-revision"
                    v-model="revision"
                    placeholder="Version"
                    :state="(errors.length > 0 || revisionValidation) ? false : null"
                    name="revision"
                    @input="revisionValidation == true ? revisionValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="revisionValidation"
                    class="text-danger"
                  >
                    {{ revisionError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-digital-form-status"
                  label-cols-md="4"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-digital-form-status"
                    v-model="status"
                    label="title"
                    :options="statusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>

        <!-- <b-card
          header-tag="header"
          class="px-3 py-2"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Fields
            </h3>
          </template>
          <b-row
            v-for="(field, index) in customFields"
            :key="index"
            ref="row"
          >
            <b-col cols="6">
              <b-form-group
                label="Key*"
                :label-for="'h-digital-form-key' + index"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Key"
                  :vid="'key' + index"
                  rules="required"
                >
                  <b-form-input
                    :id="'h-digital-form-key' + index"
                    v-model="field.key"
                    placeholder="Field Key"
                    :state="(errors.length > 0 || field.keyInvalid) ? false : null"
                    name="key"
                    @input="field.keyInvalid == true ? field.keyInvalid = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.keyInvalid"
                    class="text-danger"
                  >
                    {{ field.keyInvalidMessage }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6" />
            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                name="Value Type"
                :vid="'type' + index"
                rules="required"
              >
                <b-form-group
                  label="Value Type*"
                  :label-for="'h-digital-form-value-type' + index"
                  label-cols-md="4"
                  :state="(errors.length > 0 || field.valueTypeInvalid) ? false : null"
                >
                  <v-select
                    :id="'h-digital-form-value-type' + index"
                    v-model="field.valueType"
                    label="title"
                    :options="valueTypeOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="field.valueTypeInvalid == true ? field.valueTypeInvalid = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.valueTypeInvalid"
                    class="text-danger"
                  >
                    {{ field.valueTypeInvalidMessage }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" />

            <b-col cols="12">
              <p
                class="removeText"
                @click="removeCustomField(index)"
              >
                Remove this Field
              </p>

              <hr class="dividerHR">
            </b-col>
          </b-row>
          <b-button
            v-if="customFields.length > 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            @click="addCustomField"
          >
            <span class="align-middle">Add Field</span>
          </b-button>
          <b-row v-else>
            <b-col>
              <div class="empty_block">
                <h4>No item yet</h4>
                <b-button
                  type="button"
                  variant="flat-primary"
                  @click="addCustomField"
                >
                  <span class="align-middle">Add Field</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card> -->
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="cancelEdit"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      type: '',
      revision: '',
      number: '',
      status: 'In use',
      nameError: 'Valid name is required',
      nameValidation: false,
      revisionError: 'Valid revision is required',
      revisionValidation: false,
      numberError: 'Valid number is required',
      numberValidation: false,
      typeError: 'Valid icon is required',
      typeValidation: false,
      statusValidation: false,
      statusError: 'Valid status is required',
      trueFalseOptions: [{ title: 'Yes', code: 'yes' }, { title: 'No', code: 'no' }],
      inputTypeOptions: [
        { title: 'Text', code: 'text' },
        { title: 'Normal Textarea', code: 'textarea-normal' },
        { title: 'Rich Textarea', code: 'textarea-rich' },
        { title: 'Select', code: 'select' },
        { title: 'Multiple Select', code: 'multi-select' },
        { title: 'Checkbox', code: 'check-box' },
        { title: 'Image', code: 'image' },
      ],
      customFields: [],
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      multiSelectOptions: [
        { text: 'One Option Only', value: 'single' },
        { text: 'Multiple Options', value: 'multiple' },
      ],
      statusOptions: [
        { title: 'In use', code: 'In use' },
        { title: 'Archived', code: 'Archived' },
      ],
      valueTypeOptions: [
        { title: 'String', code: 'string' },
        { title: 'Array', code: 'array' },
      ],

      // validation rules
      required,
    }
  },
  created() {
    this.$http.get(`operation/digital-form/${this.$route.params.id}`)
      .then(response => {
        this.name = response.data.name ?? ''
        this.type = response.data.type ?? ''
        this.revision = response.data.revision
        this.number = response.data.formNumber ?? ''
        this.status = response.data.status ?? 'In use'
        // const fullData = []
        // Object.keys(response.data.fields).forEach(field => {
        //   const obj = {}
        //   obj.key = field
        //   if (Array.isArray(response.data.fields[field])) {
        //     obj.valueType = 'array'
        //   } else {
        //     obj.valueType = 'string'
        //   }

        //   fullData.push(obj)
        // })

        // this.customFields = fullData ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    addCustomField() {
      this.customFields.push({
        key: '',
        valueType: 'string',
      })
    },
    addCustomFieldOptions(index) {
      this.customFields[index].extraOptions.push({
        key: '',
        keyInvalid: false,
        keyInvalidMessage: '',
        value: '',
        valueInvalid: false,
        valueInvalidMessage: '',
      })
    },
    updateExtraFileds(index) {
      // eslint-disable-next-line no-unused-expressions
      this.customFields[index].typeInvalid === true ? this.customFields[index].typeInvalid = false : null
      if (this.customFields[index].type === 'check-box' || this.customFields[index].type === 'select' || this.customFields[index].type === 'multi-select') {
        this.customFields[index].extraOptions.push({
          key: '',
          keyInvalid: false,
          keyInvalidMessage: '',
          value: '',
          valueInvalid: false,
          valueInvalidMessage: '',
        })
      } else {
        this.customFields[index].extraOptions = []
      }
    },
    removeCustomFieldOptions(index, key) {
      if (this.customFields[index].extraOptions.length > 1) {
        this.customFields[index].extraOptions.splice(key, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one value.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeCustomField(index) {
      this.customFields.splice(index, 1)
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'operation-digital-form-index' })
          }
        })
    },
    submitForm() {
      this.$refs.digitalFormEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('formNumber', this.number)
          formData.append('type', this.type)
          formData.append('revision', this.revision)
          formData.append('status', this.status)
          const valueObj = {}
          this.customFields.forEach(field => {
            if (field.valueType === 'string') {
              valueObj[field.key] = ''
            } else {
              valueObj[field.key] = []
            }
          })

          formData.append('fields', JSON.stringify(valueObj))

          this.$http.patch(`operation/digital-form/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Changes have Saved.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'operation-digital-form-index' })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'type') {
                    this.typeError = validationError.msg
                    this.typeValidation = true
                  } else if (validationError.param === 'number') {
                    this.numberError = validationError.msg
                    this.numberValidation = true
                  } else if (validationError.param === 'revision') {
                    this.revisionError = validationError.msg
                    this.revisionValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'fields') {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: validationError.msg || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

  <style scoped>
    .removeText {
      color:#D91B35;
      cursor: pointer;
    }
    .addOptionText {
      color: #104D9D;
      cursor: pointer;
    }
    .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
      border-right-width: 1px !important;
    }
    .inlineElement {
      display: flex;
    }
  </style>
